<template>
	<footer class="footer">
		<div class="app__container">
			<p>
				<router-link :to='{name: "DeliveryPrice"}'>{{ $t('deliveryPrice.header') }}</router-link>
				<router-link :to='{name: "NoGluten"}'>{{ $t('noGluten.header') }}</router-link>
				<router-link :to='{name: "Catering"}'>{{ $t('catering.header') }}</router-link>
				<router-link :to='{name: "Contact"}'>{{ $t('contact.header') }}</router-link>
				<router-link :to='{name: "Terms"}'>{{ $t('terms.header') }}</router-link>
				<router-link :to='{name: "PrivacyPolicy"}'>{{ $t('privacy_policy.header') }}</router-link>
			</p>
			<div class="footer__payments">
				<webp-image v-for="payment in payments" :key="`payment-${payment.tag}`" :name="`footer/${payment.tag}`" ext="png" :alt="payment.alt" :lazyImg="true" />
			</div>
			<p class="footer__copy">Copyright &copy; {{ year }} {{ siteName }}</p>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: () => ({
		year: new Date().getFullYear(),
		payments: [
			{alt: 'Visa & MasterCard', tag: 'visa'},
			{alt: 'tpay', tag: 'tpay'},
			{alt: 'blik', tag: 'blik'},
			{alt: 'Google Pay', tag: 'gpay'},
		]
	}),
	computed: {
		...mapGetters(['siteName']),
	}
}
</script>

<style lang="scss">
@import '../../assets/css/breakpoints.scss';
.footer {
	background-color: var(--black);

	a {
		display: inline-block;
		font-size: 14px;
		line-height: 30px;
		padding: 10px;
		margin: 0 7.5px;
	}

	&__payments {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		margin: 20px 0;

		img {
			margin: 10px;
			height: 25px;
		}
	}

	&__copy {
		margin: 25px auto;

		color: var(--white);
		font-family: var(--font-header);
	}
}
</style>
